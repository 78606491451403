import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Chat } from 'src/app/models/chat';
import { ChatService } from 'src/app/services/chat.service';
import { SocketService } from 'src/app/services/socket.service';
import { RemoteConfigService } from 'src/app/services/remote-config.service';
import { EmojiCustom } from '../../models/emoji-custom';
import { environment } from '../../../environments/environment';
import { UserInfoService } from '../../services/user-info.service';
import { Subject, takeUntil } from 'rxjs';
import { Utils } from '../../services/utils.service';

@Component({
    selector: 'app-comment-write',
    templateUrl: './comment-write.component.html',
    styleUrls: ['./comment-write.component.scss']
})
export class CommentWriteComponent implements OnInit, OnDestroy {

    chat: Chat = new Chat();
    private lastSubmit: number;
    avatarUrl: string;
    hideEmoji: boolean = true;

    sendFill: string = '#8C8B82';

    customEmojis: EmojiCustom[];

    includes = [
        'search',
        'recent',
        'custom',
        'people',
        'symbols'
    ];

    translations = {
        search: 'Search',
        emojilist: 'List of emoji',
        notfound: 'No Emoji Found',
        clear: 'Clear',
        categories: {
            search: 'Search Results',
            recent: 'Frequently Used',
            custom: environment.name,
            people: 'Smileys & People',
            nature: 'Animals & Nature',
            foods: 'Food & Drink',
            activity: 'Activity',
            places: 'Travel & Places',
            objects: 'Objects',
            symbols: 'Symbols',
            flags: 'Flags',
        },
        skintones: {
            1: 'Default Skin Tone',
            2: 'Light Skin Tone',
            3: 'Medium-Light Skin Tone',
            4: 'Medium Skin Tone',
            5: 'Medium-Dark Skin Tone',
            6: 'Dark Skin Tone',
        }
    };


    @Input() mediaId: number;
    @Input() originalChatId: string;
    @Input() subComment: boolean = false;

    @Output() close: EventEmitter<any> = new EventEmitter();

    @ViewChild('comment') commentBox: ElementRef;
    private destroy$: Subject<void> = new Subject<void>();


    constructor(
        private socketService: SocketService,
        private chatService: ChatService,
        private config: RemoteConfigService,
        private userInfo: UserInfoService) {
        this.avatarUrl = this.userInfo.avatarUrl ?? 'assets/avatar-default.svg'

    }

    ngOnInit() {
        this.lastSubmit = this.getCurrentSeconds();

        if (this.chatService.emoji) {
            this.chatService.emoji = false;
            this.emoji();
        }

        this.chatService.getEmojis().subscribe(emojis => {
            this.customEmojis = emojis;
        });

        this.userInfo.userInfoUpdated$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.avatarUrl = this.userInfo.avatarUrl ?? 'assets/avatar-default.svg';
            });

        this.chatService.replyingComment$.subscribe((reply) => {
            if (reply && this.subComment) {
                this.chat.message = `@${reply.name ?? reply.displayName} `
            }
        });
    }

    ngAfterViewInit() {
        if (this.chatService.replying) {
            this.commentBox.nativeElement.focus();
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    getCurrentSeconds() {
        return new Date().getTime() / 1000;
    }

    send() {
        console.log(this.originalChatId)
        if (this.config.remoteConfig.comments_version === '1') {
            this.hideEmoji = true;
            this.chatService.replying = false;
            if (!this.isEmptyOrSpaces(this.chat.message)) {
                if (this.getCurrentSeconds() - this.lastSubmit > 1) {
                    this.lastSubmit = this.getCurrentSeconds();
                    this.chatService.sendMessage(this.mediaId.toString(), this.chat, this.originalChatId).subscribe();

                    this.cancel();
                }
            } else {
                this.cancel();
            }
        } else {
            if (!this.isEmptyOrSpaces(this.chat.message)) {
                if (this.getCurrentSeconds() - this.lastSubmit > 1) {
                    this.lastSubmit = this.getCurrentSeconds();

                    this.socketService.sendComment(this.chat.message, this.mediaId, +this.originalChatId);

                    this.cancel();
                }
            } else {
                this.cancel();
            }
        }
    }

    cancel() {
        this.chat = new Chat();
        this.chatService.replying = false;
        this.hideEmoji = true;
        this.close.emit(null);
    }

    isEmptyOrSpaces(str) {
        return Utils.isNullOrWhitespace(str);
    }

    emoji() {
        this.hideEmoji = this.hideEmoji !== true;
    }

    addEmoji(event) {
        if (event.emoji.native) {
            if (this.chat.message) {
                this.chat.message += event.emoji.native;
            } else {
                this.chat.message = event.emoji.native;
            }
        } else {
            if (this.chat.message) {
                this.chat.message += event.emoji.colons;
            } else {
                this.chat.message = event.emoji.colons;
            }
        }
    }

    ctrlEnter(event: KeyboardEvent) {
        if (event.code === 'Enter' && event.ctrlKey) {
            this.send();
        }
    }
}
