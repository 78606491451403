export const environment = {
    production: true,
    forceMaintenance: false,
    webSocketEnabled: false,
    googleAnalyticsEnabled: false,
    googleAnalyticsId: '',
    projectsEnabled: false,
    recaptchaKey: '6Le9MhgqAAAAANsgEdwnPNxicOKNdqiixE2L0P7k',
    recaptchaDebug: '',
    name: 'Crafters Guild',
    domain: '.craftersguild.tv',
    socketUrl: 'https://socket.craftersguild.tv',
    cookiebotId: '9bd27a51-dc60-446c-9f34-80f9f3b07a91',
    assets: {
        logo: 'https://assets.craftersguild.tv/logo_short.svg',
    },
    google: {
        client_id: '',
        project_id: '',
        auth_uri: 'https://accounts.google.com/o/oauth2/auth',
        token_uri: 'https://oauth2.googleapis.com/token',
        auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
        client_secret: ''
    },
    apple: {
        client_id: 'tv.craftersguild.web',
        scope: 'name email',
        state: '12345',
        nonce: '12345',
        redirectUri: 'https://www.craftersguild.tv/'
    },
    firebaseConfig: {
        apiKey: 'AIzaSyD5Rj6yYgmvG_ulGXK0fMHUlgPPZq8RK68',
        authDomain: 'protostreamottgeneric.firebaseapp.com',
        projectId: 'protostreamottgeneric',
        storageBucket: 'protostreamottgeneric.appspot.com',
        messagingSenderId: '383412335488',
        appId: '1:383412335488:web:5d7f55c8ba26702fb28765',
        measurementId: 'G-BN5K67JSFR'
    },
    stripe: {
        monthlyPlan: '',
        annualPlan: '',
        buyMonthlySub: '',
        buyAnnualSub: '',
        pk_test: 'pk_test_51PY5m8Ii2dKkZDGUWeio3eTHDMssOCvEDsSXeAP1MeGsHnLUjRha6hhC0RcMBGT3wXYHE8PwaeBbtyWJXyiwc0v600loRlEWfx',
        pk_live: 'pk_live_51PY5m8Ii2dKkZDGUb8WxYaF226kQG0S0OQyhXZuqkgAWFu78RQOc6xHLkl3AT8cT3MueGkWCY1BEML1fgnDg8tzx00A0cnpPBY'
    }
};
